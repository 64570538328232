import { create } from '@storybook/theming/create'

export default create({
  base: 'light',
  fontBase: '"Open Sans", sans-serif',
  fontCode: 'monospace',
  brandTitle: 'Sp Agents',
  brandUrl: 'https://sp-agents.com/',
  brandImage: '/logo.png',
})

